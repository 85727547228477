import {Injectable, isDevMode} from '@angular/core';
import {Config} from "./config";
import { HttpClient } from "@angular/common/http";

import {Platform} from "@ionic/angular";
import {environment} from "../../environments/environment";
import {AuthConfig} from "angular-oauth2-oidc";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private readonly configURL: string;
  private appConfig: Config = {} as Config;

  constructor(private readonly http: HttpClient,
              private readonly platform: Platform) {
    if (isDevMode() || environment.desktop) {
      console.log("loading dev config")
      this.configURL = 'assets/config/config.json';
    } else {
      console.log("loading prod config")
      this.configURL = 'assets/config/config.prod.json';
    }
  }

  public loadConfig(defaults?: Config): Promise<Config> {
    return new Promise<Config>(resolve => {
      this.http.get<Config>(this.configURL).subscribe({
        next: data => {
          this.appConfig = data;
          this.configurePlatform();
          console.log('Loaded config', this.appConfig);
          resolve(this.appConfig);
        },
        error: (error) => {
          console.error('Failed to load config, using defaults', error);
          this.appConfig = {...defaults} as Config;
          resolve(this.appConfig);
        }
      });
    });

  }

  private configurePlatform() {
    if (this.isIOS || this.isAndroid) {
      this.appConfig.authConfig.redirectUri = this.appConfig.appRedirectURL;
      this.appConfig.authConfig.postLogoutRedirectUri = this.appConfig.appRedirectURL;
    }
  }

  get authConfig(): AuthConfig {
    return this.appConfig.authConfig;
  }

  public getAPIBaseUrl(): string {
    return this.appConfig.baseApiURL;
  }

  public getSelfURL(): string {
    return this.appConfig.selfURL;
  }

  public getCategoryUrl(): string {
    return this.getAPIBaseUrl() + this.appConfig.categoryService;
  }

  public getEventUrl(): string {
    return this.getAPIBaseUrl() + this.appConfig.eventService;
  }

  public getCheckListUrl(): string {
    return this.getAPIBaseUrl() + this.appConfig.checkListService;
  }

  public getPhotoUrl(): string {
    return this.getAPIBaseUrl() + this.appConfig.photoService;
  }

  public getEventsForWeekUrl(date: Date): string {
    let previousMonday = this.clearTime(this.getPreviousMonday(date));
    return this.getAPIBaseUrl() + this.appConfig.eventService + "/week/" + previousMonday.toISOString();
  }

  public clearTime(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  }

  public getPreviousMonday(date: Date): Date {
    const day = date.getDay();
    let result : Date;
    if (day == 0) {
      result = this.subtractDays(date, 6);
    } else {
      result = this.subtractDays(date, (day-1));
    }
    return result;
  }

  private subtractDays(date: Date, days: number): Date {
    return new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  }

  get isIOS(): boolean {
    return this.platform.is('ios') && this.platform.is('capacitor');
  }

  get isAndroid(): boolean {
    return this.platform.is('android') && this.platform.is('capacitor');
  }

  get selfURL(): string {
    return this.appConfig.selfURL;
  }

  getLearnerUrl() {
    return `${this.appConfig.baseApiURL}${this.appConfig.learnerService}`;
  }

  getTopicTagUrl() {
    return `${this.appConfig.baseApiURL}${this.appConfig.topicTagService}`;
  }

  getCategorySummaryURL(duration? : string) {
    if (!duration) {
      return `${this.appConfig.baseApiURL}${this.appConfig.categorySummary}`;
    }
    return `${this.appConfig.baseApiURL}${this.appConfig.categorySummary}/${duration}`;
  }

  getCategoryLearnerSummaryURL(duration : string) {
    return `${this.appConfig.baseApiURL}${this.appConfig.learnerSummary}/${duration}`;
  }

}
