import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {ErrorResponse} from './error.model';
import {AuthService} from "../auth/auth.service";
import {OnlineOfflineService} from "../onlineOffline/online-offline.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  private online: boolean = false;
  private offlineMessageShown = false;

  constructor(private readonly errorService: ErrorService,
              private readonly authenticationService: AuthService,
              private readonly onlineOfflineService: OnlineOfflineService) {
    this.registerToEvents(onlineOfflineService);
  }

  private registerToEvents(onlineOfflineService: OnlineOfflineService) {
    onlineOfflineService.connectionChanged.subscribe(online => {
      this.online = online;
      if (online) {
        this.offlineMessageShown = false;
      }
    });
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        retry({
          count: 3,
          delay: 100
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMessage;
          console.log(`Error Handler: ${JSON.stringify(error)}`);
          if (error.error instanceof ErrorEvent) {
            // client side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // service side error
            console.log(`Error ${JSON.stringify(error)}`);

            if (error.status == 401) {
              this.authenticationService.logout();
            }

            let errorResponse = error.error as ErrorResponse;
            if (errorResponse.message) {
              errorMessage = errorResponse.message;
            } else if (this.online) {
                errorMessage = 'Unable to complete request';
              } else {
                errorMessage = `You're offline and we don't have this information cached, please try again when you're online.`;
              }
            }


          console.error(`Error received! ${errorMessage}`);
          if (!this.online && !this.offlineMessageShown) {
            this.errorService.registerError(errorMessage);
            this.offlineMessageShown = true;
          } else if (this.online) {
            this.errorService.registerError(errorMessage);
          }

          return throwError(() => error);

        })
      )

  }

}
