import {Injectable, NgZone} from '@angular/core';
import {AppConfigService} from "../../config/app-config.service";
import {NullValidationHandler, OAuthService, UserInfo} from "angular-oauth2-oidc";
import {App, URLOpenListenerEvent} from "@capacitor/app";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Browser} from "@capacitor/browser";
import {BehaviorSubject, interval, takeWhile} from "rxjs";
import {LoggedInUser} from "./logged-in-user.model";
import {map, switchMap, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService  {

  private readonly loggedInUserRefreshSubject = new BehaviorSubject<LoggedInUser|null>(null);
  loggedInUser$ = this.loggedInUserRefreshSubject.asObservable();


  public hasValidAccessToken : boolean = false;
  public realmRoles : string[] = [];
  public userProfile : UserInfo | null = null;

  constructor(private readonly appConfig : AppConfigService,
              private readonly oauthService : OAuthService,
              private readonly zone: NgZone,
              private readonly activatedRoute : ActivatedRoute,
              private readonly router: Router
  ) {}

  public async init(): Promise<void> {
    this.configure();

    /**
     * The library offers a bunch of events.
     */
    this.oauthService.events.subscribe(eventResult => {
      if (this.isAccessTokenEvent(eventResult)) {
        console.debug("LibEvent: ", JSON.stringify(eventResult));
        this.loadUserProfile().then()
      }
      this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    });

    /**
     * Load discovery document when the app inits
     */
    try {
      console.log("Loading discovery document");
      await this.oauthService.loadDiscoveryDocument();
      console.log("Discovery document loaded");
      /**
       * Do we have a valid access token? -> User does not need to log in
       */
      this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
      /**
       * Always call tryLogin after the app and discovery document loaded, because we could come back from Keycloak login page.
       * The library needs this as a trigger to parse the query parameters we got from Keycloak.
       */
      this.oauthService.tryLogin().then(() => {
        if (this.hasValidAccessToken) {
          this.loadLoggedInUser();
        }
      });
    } catch (error) {
      console.error("loadDiscoveryDocument", error);
    }


  }

  private async loadLoggedInUser() {
    await this.loadUserProfile();
    this.realmRoles = this.getRealmRoles();
    this.loggedInUserRefreshSubject.next(new LoggedInUser(this.realmRoles, this.userProfile));
  }

  public isAccessTokenEvent(eventResult: any): boolean {
    return eventResult.type === 'token_received' || eventResult.type === 'token_refreshed' || eventResult.type === 'token_expires';
  }

  public isLoggedIn(): boolean {
    return this.hasValidAccessToken;
  }

  public getAuthToken(): string {
    return this.oauthService.getAccessToken();
  }

  public getUsername() : string {
    let idClaims = this.oauthService.getIdentityClaims();
    if (idClaims) {
      return idClaims['preferred_username'];
    } else if (this.userProfile) {
      return this.userProfile['preferred_username'];
    }
    throw new Error('No username found in token or user profile');
  }

  public login(): void {
    this.oauthService.loadDiscoveryDocumentAndLogin()
      .catch(error => {
        console.error("loadDiscoveryDocumentAndLogin", error);
      });
  }

  /**
   * Calls the library revokeTokenAndLogout() method.
   */
  public logout(): void {
    this.oauthService.revokeTokenAndLogout()
      .then(() => {
        this.userProfile = null;
        this.realmRoles = [];
        this.loggedInUserRefreshSubject.next(null);
      })
      .catch(error => {
        console.error("revokeTokenAndLogout", error);
      });
  }

  public refreshLoggedInUser() {
    this.startRoleCheck();
  }

  private startRoleCheck() {
    interval(500) // 1 minute interval
      .pipe(
        switchMap(() => this.oauthService.refreshToken()),
        map(() => this.getRealmRoles()),
        tap((roles) => {
          if (roles.includes('event.access')) {
            this.loadLoggedInUser().then();
          }
        }),
        takeWhile((roles) => !roles.includes('event.access'), true)
      )
      .subscribe();
  }

  /**
   *  Use this method only when an id token is available.
   *  This requires a specific mapper setup in Keycloak. (See README file)
   *
   *  Parses realm roles from identity claims.
   */
  public getRealmRoles(): string[] {
    let idClaims = this.oauthService.getIdentityClaims()
    if (!idClaims){
      console.error("Couldn't get identity claims, make sure the user is signed in.")
      return [];
    }
    if (!idClaims.hasOwnProperty("realm_roles")){
      console.error("Keycloak didn't provide realm_roles in the token. Have you configured the predefined mapper realm roles correct?")
      return [];
    }

    let realmRoles = idClaims["realm_roles"]
    return realmRoles ?? [];
  }

  public async loadUserProfile() {
    try {
      let userProfileResult = await this.oauthService.loadUserProfile();
      if ("info" in userProfileResult) {
        this.userProfile = userProfileResult.info as UserInfo;
      } else {
        this.userProfile = userProfileResult as UserInfo;
      }
    } catch(error) {
        console.error("loadUserProfile", error);
    }
  }


  /**
   * Configures the app for web deployment
   * @private
   */
  private configure(): void {
    this.appConfig.authConfig.openUri = (url: string) => {
      Browser.open( {
        url: url,
        windowName: '_self'
      }).catch(error => {
        console.error("Browser open error", error);
      });

    }


    this.oauthService.configure(this.appConfig.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new  NullValidationHandler();

    if (this.appConfig.isIOS || this.appConfig.isAndroid) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        let url = new URL(event.url);
        if (url.protocol != "heclogin:") {
          console.warn("Ignoring URL", url);
          // Only interested in redirects to heclogin://login
          return;
        }

        this.zone.run(() => {
          if (this.appConfig.isIOS) {
            Browser.close();
          }
          // Building a query param object for Angular Router
          const queryParams: Params = {};
          for (const [key, value] of url.searchParams.entries()) {
            queryParams[key] = value;
          }

          // Add query params to current route
          this.router.navigate(
            ['tabs','home'],
            {
              relativeTo: this.activatedRoute,
              queryParams: queryParams,
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            })
            .then(() => {
              // After updating the route, trigger login in oauthlib and
              this.oauthService.tryLogin().then(() => {
                if (this.hasValidAccessToken) {
                  this.loadLoggedInUser();
                }
              })
            })
            .catch(error => console.error(error));

        });
      });
    }
  }

  openAccountSettings() {
    Browser.open( {
      url: this.appConfig.authConfig.issuer + '/account?referrer=' + this.appConfig.authConfig.clientId,
      windowName: '_self'
    });
  }
}
