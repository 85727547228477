import {UserInfo} from "angular-oauth2-oidc";

export class LoggedInUser {
  constructor(
    public roles: string[],
    public userInfo : UserInfo | null,
  ) {}

  // Method to check if the user has a specific role
  hasRole(role: string): boolean {
    return this.roles.includes(role);
  }

  getSub() {
    if (this.userInfo) {
      return this.userInfo['sub'];
    }
    return '';
  }

}
