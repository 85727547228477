import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {ErrorService} from "./api/error/error.service";
import * as Sentry from "@sentry/angular";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private readonly ngZone: NgZone,
              private readonly errorService: ErrorService) {
  }

  handleError(error: any): void {
    this.ngZone.run(() => {
      Sentry.captureException(error);
      // is error a string?
      if (typeof error === 'string') {
        // do something with the error
        console.error('An error occurred:', error);
        this.errorService.registerError(error);
      } else if (typeof error === 'object') {
        // is error an instance of Error?
        if (error instanceof Error) {
          // do something with the error
          console.error('An error occurred:', error.message);
          this.errorService.registerError(error.message);
        } else {
          // hope that to string is implemented
          console.error('An error occurred:', error);
          this.errorService.registerError(error);
        }
      }


    });
  }
}
